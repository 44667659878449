/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import type { EuiRangeTick } from '@elastic/eui'
import { EuiRange, EuiSpacer, EuiText, useGeneratedHtmlId } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'

type Tick = 0 | 1 | 2

export interface Props extends WrappedComponentProps {
  onChange: (searchPower: number) => void
  value: number
  disabled?: boolean
  presets: Array<{
    label: string
    searchPower: number
  }>
}

const presetDescriptions = [
  <FormattedMessage
    id='project.searchPower.onDemandDescription'
    defaultMessage='Do not provision search resources or incur costs during inactive periods, resulting in higher 
    first-query latency and lower max throughput, especially after inactive periods.'
  />,
  <FormattedMessage
    id='project.searchPower.performantDescription'
    defaultMessage='Use minimum search resources possible during inactive periods to keep data available while 
    maintaining good first-query performance.'
  />,
  <FormattedMessage
    id='project.searchPower.highThroughputDescription'
    defaultMessage='Add more search resources during active and inactive periods to achieve higher search throughput 
    than Performant.'
  />,
]

const SelectSearchPowerPreset = ({ onChange, value, disabled, intl, presets }: Props) => {
  const tick = getTickValueForSearchPower()

  const htmlId = useGeneratedHtmlId()

  const { formatMessage } = intl

  const ticks: EuiRangeTick[] = presets.map(({ label }, index) => ({
    label,
    value: index,
    accessibleLable: label,
  }))

  return (
    <Fragment>
      <div style={{ margin: '0 1.2rem', whiteSpace: 'nowrap' }}>
        <EuiRange
          id={htmlId}
          value={tick}
          onChange={handleRangeChange}
          min={0}
          max={2}
          fullWidth={true}
          showInput={false}
          showRange={true}
          showTicks={true}
          ticks={ticks}
          aria-label={formatMessage({
            id: 'project.searchPower.range.aria-label',
            defaultMessage: 'Configure search power',
          })}
          disabled={disabled}
        />
      </div>

      <EuiSpacer size='l' />

      <EuiText size='s' color='subdued' style={{ minHeight: '4.5em' }}>
        {presetDescriptions[tick]}
      </EuiText>
    </Fragment>
  )

  function getTickValueForSearchPower(): Tick {
    const index = presets.findIndex(({ searchPower }) => searchPower === value)

    if (index === -1) {
      return 1 // Use Performant as a fallback
    }

    return index as Tick
  }

  function handleRangeChange(event): void {
    const newTick = parseInt(event.currentTarget.value, 10)

    const newSearchPower = presets[newTick]?.searchPower || 0

    onChange(newSearchPower)
  }
}

export default injectIntl(SelectSearchPowerPreset)
