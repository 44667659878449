/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import genericMessages from '@modules/project-lib/genericMessages'

import type { SearchPowerPreset } from './types'

export const presets: SearchPowerPreset[] = [
  {
    label: genericMessages.onDemandPreset,
    searchPower: 0,
  },
  {
    label: genericMessages.performantPreset,
    searchPower: 100,
  },
  {
    label: genericMessages.highThroughputPreset,
    searchPower: 250,
  },
]
