/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import { useGetCostsItemsV2 } from '@modules/billing-lib/hooks'
import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import { getSaasLatestTrial } from '@modules/profile-lib/trial'
import type { Product } from '@modules/billing-api/v1/types'

import ProjectCosts from './ProjectCosts'

type Props = {
  saasUser: SaasUserResponse
}

/**
 * An empty payload to render against in the case of the actual payload not
 * having any line items. This will catch the imtermittent case of a project
 * being present but not having any populated billing data yet.
 */
const projectProductsBlank: Product[] = [
  {
    type: 'elasticsearch',
    total_ecu: 0,
    product_line_items: [
      {
        name: 'VCU',
        total_ecu: 0,
        type: 'VCU',
        sku: '',
        unit: 'VCU',
        quantity: {
          value: 0,
          formatted_value: '0.0000 VCUs',
        },
        display_quantity: {
          value: 0,
          formatted_value: '0.0000 VCUs',
          type: 'default',
        },
        rate: {
          value: 0.0,
          formatted_value: '0.0000 per VCU',
        },
      },
      {
        name: 'Retained data',
        total_ecu: 0.0,
        type: 'Search AI Lake',
        sku: '',
        unit: 'GB',
        quantity: {
          value: 0.0,
          formatted_value: '0.0000 GBs',
        },
        display_quantity: {
          value: 0.0,
          formatted_value: '0.0000 GBs',
          type: 'default',
        },
        rate: {
          value: 0.0,
          formatted_value: '0.0000 per GB',
        },
      },
    ],
  },
]

const Costs: React.FunctionComponent<Props> = ({ saasUser }) => {
  const trial = getSaasLatestTrial(saasUser)

  const costsQuery = useGetCostsItemsV2({
    pathParameters: { organization_id: saasUser.user.organization_id },
    queryParameters: { from: trial?.start, to: trial?.end },
  })

  if (costsQuery.isLoading) {
    return <EuiSkeletonText />
  }

  const products = costsQuery.data?.products ?? []

  const rawProjectProducts = products.filter((product) => product.type !== 'deployment')

  const projectProducts = rawProjectProducts.length > 0 ? rawProjectProducts : projectProductsBlank

  return (
    <Fragment>{projectProducts.length > 0 && <ProjectCosts products={projectProducts} />}</Fragment>
  )
}

export default Costs
