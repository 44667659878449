/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useMemo } from 'react'

import { useGetBasePricesV1 } from '@modules/billing-lib/hooks'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'

import { getPriceHashmap } from './utils'

interface Props {
  id: string
  quantity: number
}

const PreviewCost = ({ id, quantity }: Props) => {
  const { data = [] } = useGetBasePricesV1()
  const priceHashmap = useMemo(() => getPriceHashmap(data), [data])

  return (
    <s>
      <CuiElasticConsumptionUnits
        value={(priceHashmap[id] || 0) * quantity}
        unit='none'
        dp={4}
        withSymbol={false}
      />
    </s>
  )
}

export default PreviewCost
