/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { KibanaResourceInfo } from '@modules/cloud-api/v1/types'
import {
  GET_STARTED_URLS,
  OBSERVABILITY_GET_STARTED_URL,
  SEARCH_GET_STARTED_URL,
  SECURITY_GET_STARTED_URL,
} from '@modules/deployment-creation-lib/urls'
import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import type { StackDeployment } from '@modules/ui-types'

import { gte, lt } from './semver'
import { getEndpointForResource } from './serviceProviderEndpoints'
import { getFirstAvailableSliderClusterFromGet } from './stackDeployments/selectors/available'
import { getResourceVersion } from './stackDeployments/selectors/fundamentals'

import type { GetDeepLinkFn } from './serviceProviderEndpoints'

export function kibanaSecurityManagementUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/management/security/users`
    }

    return `/app/management/security/users` // 7.9.0+
  }
}

export function kibanaSnapshotAndRestoreUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/management/elasticsearch/snapshot_restore/snapshots`
    }

    return `/app/management/data/snapshot_restore/snapshots` // 7.9.0+
  }
}

export function kibanaSnapshotPolicyUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/management/elasticsearch/snapshot_restore/policies`
    }

    return `/app/management/data/snapshot_restore/policies` // 7.9.0+
  }
}

export function kibanaIlmPoliciesUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/management/elasticsearch/index_lifecycle_management/policies`
    }

    return `/app/management/data/index_lifecycle_management/policies` // 7.9.0+
  }
}

export function kibanaApmTutorialUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/home/tutorial/apm`
    }

    return `/app/home#/tutorial/apm` // 7.9.0+
  }
}

export function kibanaRemoteClustersUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/management/elasticsearch/remote_clusters`
    }

    return `/app/management/data/remote_clusters` // 7.9.0+
  }
}

export function kibanaUpgradeAssistantUrl({ resource }: { resource: KibanaResourceInfo | null }) {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (kibanaVersion && lt(kibanaVersion, `7.9.0`)) {
      return `/app/kibana#/management/elasticsearch/upgrade_assistant`
    }

    return `/app/management/stack/upgrade_assistant` // 7.9.0+
  }
}

export function getKibanaBasedAppUrl({
  deployment,
  getDeepLink,
}: {
  deployment: StackDeployment
  getDeepLink: GetDeepLinkFn
}): string {
  const kibanaResource = getFirstAvailableSliderClusterFromGet({
    deployment,
    sliderInstanceType: `kibana`,
  })

  return getEndpointForResource({
    resource: kibanaResource,
    resourceType: 'kibana',
    getDeepLink,
  })
}

export function kibanaGettingStartedUrl({
  resource,
  showGuidedOnboardingPage,
  discoveryUseCase,
  deeplinkToSecurity,
  deeplinkToSearch,
  solutionType,
}: {
  resource: KibanaResourceInfo | null
  showGuidedOnboardingPage?: boolean | undefined
  discoveryUseCase?: string
  deeplinkToSecurity?: boolean | undefined
  deeplinkToSearch?: boolean | undefined
  solutionType: DefaultSolutionView | undefined
}): string {
  const kibanaVersion = getResourceVersion({ resource })

  return getEndpointForResource({
    resource,
    resourceType: 'kibana',
    getDeepLink,
  })

  function getDeepLink(): string | null {
    if (!kibanaVersion) {
      return `/app/home`
    }

    if (isKibanaBefore7_9_0(kibanaVersion)) {
      return `/app/kibana#/home`
    }

    if (solutionType !== undefined) {
      return GET_STARTED_URLS[solutionType]
    }

    const shouldRedirectToSecurityHub =
      gte(kibanaVersion, `8.13.0`) && discoveryUseCase === 'security' && deeplinkToSecurity === true

    if (shouldRedirectToSecurityHub) {
      return SECURITY_GET_STARTED_URL
    }

    const shouldRedirectToSearchHome =
      gte(kibanaVersion, `8.13.0`) &&
      discoveryUseCase === 'elasticsearch' &&
      deeplinkToSearch === true

    if (shouldRedirectToSearchHome) {
      return SEARCH_GET_STARTED_URL
    }

    const shouldRedirectToObservability =
      gte(kibanaVersion, `8.15.0`) && discoveryUseCase === 'observability'

    if (shouldRedirectToObservability) {
      return OBSERVABILITY_GET_STARTED_URL
    }

    const gettingStartedUrl = '/app/home#/getting_started'

    if (gte(kibanaVersion, `8.4.0`) && showGuidedOnboardingPage === true) {
      return discoveryUseCase
        ? `${gettingStartedUrl}?useCase=${discoveryUseCase}`
        : gettingStartedUrl // 8.4+ with LD flag enabled
    }

    return `/app/home`
  }
}

function isKibanaBefore7_9_0(kibanaVersion: string): boolean {
  return lt(kibanaVersion, `7.9.0`)
}
