/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { parse } from 'query-string'

import history from '@modules/utils/history'

import { applyGoogleTrackingV4FormSubmission } from '@/apps/userconsole/lib/googleTracking'
import { buildFirstSignInRedirectUrl, buildLoginUrl } from '@/lib/urlUtils'
import EmailVerification from '@/components/Login/EmailVerification'
import AppLoadingRoot from '@/components/AppLoadingRoot'

import { getCreateUserPayload, isRequireActivationError } from '../CreateAccountForm/lib'

import { formId } from './Form'
import SignUpPage from './SignUpPage'
import { GovCloudSignUpPage } from './GovCloudSignUpPage'

import type { AllProps } from './types'

type State = {
  createdButVerificationRequired: boolean
  email: string | undefined
  loadedFlags: boolean
}

class UserRegistration extends Component<AllProps, State> {
  state: State = {
    email: undefined,
    loadedFlags: false,
    createdButVerificationRequired: false,
  }

  componentDidMount() {
    this.onLoadedFlags()
  }

  componentDidUpdate() {
    this.onLoadedFlags()
  }

  render() {
    const {
      downloads,
      isGovCloud,
      source,
      isLaunchDarklyActivated,
      locationQueryString,
      location,
      resetLoginRequest,
      location: { search },
    } = this.props

    const { email, loadedFlags } = this.state

    if (this.shouldVerifyEmail() && email) {
      return (
        <EmailVerification
          location={location}
          onCancel={() => {
            resetLoginRequest()
            return history.push(buildLoginUrl({ locationQueryString }))
          }}
          email={email}
        />
      )
    }

    if (isGovCloud) {
      return (
        <GovCloudSignUpPage createUser={this.createUser} source={source} downloads={downloads} />
      )
    }

    if (isLaunchDarklyActivated && !loadedFlags) {
      return <AppLoadingRoot />
    }

    const redirectTo = buildFirstSignInRedirectUrl(search)

    return (
      <SignUpPage
        createUser={this.createUser}
        isMarketplaceSignupEnabled={true}
        redirectTo={redirectTo}
      />
    )
  }

  createUser = ({ email, password, captcha_token }) => {
    const {
      createUser: createUnverifiedUser,
      createSaasActivatedUser,
      location: { search },
      googleTrackingIdV4Enabled,
    } = this.props

    const { onboarding_token } = parse(search.slice(1))
    const createUser = onboarding_token ? createSaasActivatedUser : createUnverifiedUser

    return createUser({
      password,
      email,
      captcha_token,
      ...getCreateUserPayload(search),
    }).then(({ payload }) => {
      if (googleTrackingIdV4Enabled) {
        applyGoogleTrackingV4FormSubmission({
          email,
          formId,
        })
      }

      if (payload?.user.require_email_verification) {
        return this.setState({ createdButVerificationRequired: true, email })
      }

      return this.login({ username: email, password })
        .then((response) => {
          if (!response) {
            return
          }

          const { token, isUnverifiedUser } = response

          // @elastic.co users have to verify their email before login,
          // these users get 200 but no jwt in the response, so we send them to the please verify page
          if (!token || isUnverifiedUser) {
            this.setState({ createdButVerificationRequired: true, email })
          }
        })
        .catch(() => {
          this.setState({ createdButVerificationRequired: true, email })
        })
    })
  }

  login = ({ username, password }) => {
    const {
      loginAndRedirect,
      location: { search },
    } = this.props
    // For current implementation, when OKTA sends the user back to our signup page, there is no user session,
    // it expects that on success we redirect the user back to them(OKTA). The fromURI encodes where the user
    // should be redirected to from OKTA on authentication
    const redirectTo = buildFirstSignInRedirectUrl(search)

    return loginAndRedirect({
      oktaRedirectUrl: redirectTo,
      redirectTo,
      email: username,
      password,
    })
  }

  shouldVerifyEmail(): boolean {
    const { loginRequest, registrationRequest } = this.props
    const { createdButVerificationRequired } = this.state
    const { error: loginError, inProgress: loginInProgress } = loginRequest
    const { error: registrationError, inProgress: registrationInProgress } = registrationRequest

    // registration errors mean we should not verify yet but login error could means we have to verify
    if (loginInProgress || registrationInProgress || registrationError) {
      return false
    }

    if (createdButVerificationRequired) {
      return createdButVerificationRequired
    }

    if (!loginError) {
      return false
    }

    const needsActivation = isRequireActivationError(loginError)

    return needsActivation
  }

  onLoadedFlags() {
    const { isLaunchDarklyActivated, ldClient, launchDarklyClientInitializationTimeoutSeconds } =
      this.props
    const { loadedFlags } = this.state

    if (isLaunchDarklyActivated && !loadedFlags) {
      ldClient
        ?.waitForInitialization(launchDarklyClientInitializationTimeoutSeconds)
        .then(() => this.setState({ loadedFlags: true }))
    }
  }
}

export default withLDConsumer()(UserRegistration)
