/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import moment from 'moment'
import { Fragment } from 'react'

import { EuiBadge, EuiBasicTable } from '@elastic/eui'
import type { EuiBasicTableColumn } from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { components } from '@modules/billing-api/v1/types'
import type { InstanceType } from '@modules/ui-types/billing'

import QuantityTableInfo from '../components/QuantityTableInfo'
import PromotionalPeriodBadge from '../components/PromotionalPeriodBadge'
import PreviewCost from '../components/PreviewCost'

import { getBillingDimensionName, getProductDetailsItems } from './utils'

import type { PLI } from './types'

const tableCss = css({
  paddingLeft: 15,
  paddingRight: 30,
  '& tr:last-of-type td': { borderBottom: 'none ' },
})

interface Props {
  product_line_items: Array<components['schemas']['ProductLineItem']>
  instanceType: InstanceType
}

const ProductDetailsPanel = ({ product_line_items, instanceType }: Props) => {
  const isServerlessInstance = instanceType !== 'deployment'

  const getLineItemBadge = (item: PLI) => {
    if (isServerlessInstance && item.total_ecu === 0) {
      return <PromotionalPeriodBadge />
    }

    if (item.to && item.from) {
      return (
        <EuiBadge color='hollow'>{`${moment(item.from).format('YYYY/MM/DD')} - ${moment(
          item.to,
        ).format('YYYY/MM/DD')}`}</EuiBadge>
      )
    }

    if (item.to) {
      return (
        <EuiBadge color='hollow'>
          <FormattedMessage
            id='product-details-panel.name.to-badge'
            defaultMessage='up until {date}'
            values={{ date: moment(item.to).format('MMM YYYY') }}
          />
        </EuiBadge>
      )
    }

    return null
  }

  const getTotalCost = (item: PLI) => {
    if (item.isTierRootPLI) {
      return null
    }

    if (isServerlessInstance && item.total_ecu === 0) {
      return <PreviewCost id={item.sku} quantity={item.quantity.value} />
    }

    return (
      <CuiElasticConsumptionUnits value={item.total_ecu} unit='none' dp={4} withSymbol={false} />
    )
  }

  const columns: Array<EuiBasicTableColumn<PLI>> = [
    {
      name: <FormattedMessage id='product-details-panel.name' defaultMessage='Component' />,
      width: '43%',
      render: (item: PLI) => {
        if (item.isTier) {
          return <em css={css({ marginLeft: 12 })}>{item.name}</em>
        }

        return (
          <Fragment>
            <p css={css({ marginRight: 8 })}>{item.name}</p>
            {getLineItemBadge(item)}
          </Fragment>
        )
      },
    },
    {
      name: (
        <FormattedMessage
          id='product-details-panel.billing-dimension'
          defaultMessage='Billing dimension'
        />
      ),
      render: (item: PLI) => !item.isTierRootPLI && getBillingDimensionName(item.type),
    },
    {
      name: <FormattedMessage id='product-details-panel.quantity' defaultMessage='Quantity' />,
      render: (item: PLI) => {
        if (item.isTierRootPLI) {
          return null
        }

        return (
          <QuantityTableInfo
            unit={item.unit}
            display_quantity={item.display_quantity}
            quantity={item.quantity.formatted_value}
          />
        )
      },
    },
    {
      name: (
        <FormattedMessage
          id='product-details-panel.item-price-name'
          defaultMessage='Price (in ECU)'
        />
      ),
      render: (item: PLI) => !item.isTierRootPLI && item.rate.formatted_value,
    },
    {
      name: <FormattedMessage id='product-details-panel.cost' defaultMessage='Cost (in ECU)' />,
      render: (item: PLI) => getTotalCost(item),
      align: 'right',
    },
  ]

  return (
    <EuiBasicTable
      items={getProductDetailsItems(product_line_items)}
      columns={columns}
      css={tableCss}
    />
  )
}

export default ProductDetailsPanel
