/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexItem, EuiSpacer } from '@elastic/eui'

import { createDeploymentUrl } from '@modules/cloud-api/v1/urls'
import type { DeploymentCreateRequest, DeploymentCreateResponse } from '@modules/cloud-api/v1/types'
import { getResponseStatus } from '@modules/utils/ajax'
import { AjaxRequestError } from '@modules/ui-types'
import { getUserUsecase } from '@modules/profile-lib'
import { isTrialUser } from '@modules/billing-lib/utils'
import { CuiAlert } from '@modules/cui/Alert'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { isApiErrorCollection } from '@modules/query/helpers'
import { getSolutionView } from '@modules/deployment-creation-wizard/lib'

import { getCreatePayload, getClaimDeploymentRequestPayload } from '@/lib/stackDeployments/crud'
import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { kibanaGettingStartedUrl } from '@/lib/serviceProviderDeepLinks'

import ApiRequestExample from '../../../ApiRequestExample'
import SpinButton from '../../../SpinButton'

import type { FC } from 'react'
import type { AllProps as Props } from './types'

const CreateDeploymentButton: FC<Props> = ({
  filterIngestPlugins = false,
  disabled,
  createStackDeploymentRequest,
  fetchStackDeploymentRequest,
  showApiRequest,
  profile,
  resetCreateDeployment,
  isByokToggleEnabled,
  claimInstantStackDeploymentRequest,
  claimInstantStackDeployment,
  renderClaimStackDeploymentError,
  editorState,
  stackVersions,
  region,
  createDeployment: createDeploymentProp,
  redirectToStackGettingStarted,
  fetchProfile,
  isUserconsole,
}) => {
  useEffect(resetCreateDeployment, [resetCreateDeployment])

  const [, flags] = useFlagsWhenLoaded()

  const [byokError, setByokError] = useState(false)

  const displayApiRequestLink = !disabled && showApiRequest
  const isTrial = profile && isTrialUser(profile)
  const deploymentRequestProgress =
    createStackDeploymentRequest.inProgress || // creating deployment
    (claimInstantDeploymentProgress() && !createStackDeploymentErrors()) ||
    fetchStackDeploymentRequest.inProgress // fetching claimed deployment

  return (
    <EuiFlexItem style={{ textAlign: 'center' }} grow={false}>
      <div>
        <SpinButton
          data-test-id='submit-create-deployment'
          className='create-deployment-action-button'
          fill={true}
          disabled={disabled}
          onClick={onClickCreate}
          spin={deploymentRequestProgress}
        >
          <FormattedMessage
            id='create-deployment-from-template.create-deployment'
            defaultMessage='Create deployment'
          />
        </SpinButton>

        {byokError && (
          <Fragment>
            <EuiSpacer size='xs' />
            <CuiAlert type='danger' data-test-id='create-deployment-validation-error'>
              <FormattedMessage
                id='create-deployment-button.error'
                defaultMessage='A key must be provided for encryption at rest'
              />
            </CuiAlert>
          </Fragment>
        )}

        {displayApiRequestLink && !isTrial && (
          <ApiRequestExample
            method='POST'
            endpoint={createDeploymentUrl()}
            body={getCreateDeploymentPayload()}
          />
        )}
      </div>
    </EuiFlexItem>
  )

  function createStackDeploymentErrors() {
    return (
      createStackDeploymentRequest.error || // creating deployment
      fetchStackDeploymentRequest.error // fetching claimed deployment
    )
  }

  function claimInstantDeploymentProgress() {
    return (
      claimInstantStackDeploymentRequest.inProgress || // claiming instant deployment
      claimInstantStackDeploymentRequest.isDone || // instant deployment claimed
      getResponseStatus(claimInstantStackDeploymentRequest.error) === 404 // instant deployment not found, switch to create deployment
    )
  }

  function onClickCreate() {
    if (profile && isTrialUser(profile) && flags.claimInstantDeployment) {
      claimInstantDeployment()
      return
    }

    createDeployment()
  }

  function getCreateDeploymentPayload(): DeploymentCreateRequest | null {
    if (!region) {
      return null
    }

    const payload = getCreatePayload({
      region,
      editorState,
      filterIngestPlugins,
      stackVersions,
    })

    return payload
  }

  async function claimInstantDeployment() {
    const createDeploymentPayload = getCreateDeploymentPayload()

    if (!createDeploymentPayload) {
      return
    }

    const claimDeploymentRequestPayload = getClaimDeploymentRequestPayload(createDeploymentPayload)

    if (claimDeploymentRequestPayload) {
      try {
        const deployment = await claimInstantStackDeployment(claimDeploymentRequestPayload)
        const kibanaResource = getFirstSliderClusterFromGet({
          deployment,
          sliderInstanceType: `kibana`,
        })
        const discoveryUseCase = profile && getUserUsecase(profile)
        const kibanaLinkUrl = kibanaGettingStartedUrl({
          resource: kibanaResource,
          showGuidedOnboardingPage: flags.guidedOnboarding === true,
          discoveryUseCase: discoveryUseCase ? discoveryUseCase : undefined,
          solutionType: getSolutionView(deployment),
        })

        if (kibanaLinkUrl) {
          window.location.replace(kibanaLinkUrl)
        }
      } catch (err) {
        if (err instanceof AjaxRequestError) {
          // NO standby deployment is available, so create a deployment - we rely on other errors being rendered elsewhere
          if (getResponseStatus(err) === 404) {
            createDeployment()
            return
          }

          renderClaimStackDeploymentError(err)
        }

        if (isApiErrorCollection(err)) {
          if (err.statusCode === 404) {
            createDeployment()
            return
          }
        }
      }
    }
  }

  function validateCreateDeployment(createDeploymentPayload: DeploymentCreateRequest): boolean {
    if (isByokToggleEnabled && createDeploymentPayload.settings?.byok === undefined) {
      setByokError(true)

      return false
    }

    setByokError(false)

    return true
  }

  async function createDeployment() {
    const createDeploymentPayload = getCreateDeploymentPayload()

    if (!createDeploymentPayload || !validateCreateDeployment(createDeploymentPayload)) {
      return
    }

    const actionResult = await createDeploymentProp({
      deployment: createDeploymentPayload,
      profile,
    })

    if (actionResult.error || !actionResult.payload) {
      return // we rely on the error being rendered elsewhere
    }

    if (isUserconsole) {
      fetchProfile()
    }

    const response: DeploymentCreateResponse = actionResult.payload
    const { id } = response

    redirectToStackGettingStarted(id)
  }
}

export default CreateDeploymentButton
