/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  actions: {
    id: 'projects.messages.actions',
    defaultMessage: 'Actions',
  },
  apm: {
    id: 'projects.messages.apm',
    defaultMessage: 'APM',
  },
  cancel: {
    id: 'projects.messages.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'projects.messages.close',
    defaultMessage: 'Close',
  },
  complete: {
    id: 'projects.messages.complete',
    defaultMessage: 'Complete',
  },
  configure: {
    id: 'projects.messages.configure',
    defaultMessage: 'Configure',
  },
  createdAt: {
    id: 'projects.messages.createdAt',
    defaultMessage: 'Created at',
  },
  createdBy: {
    id: 'projects.messages.createdBy',
    defaultMessage: 'Created by',
  },
  createdOn: {
    id: 'projects.messages.createdOn',
    defaultMessage: 'Created on',
  },
  current: {
    id: 'projects.messages.current',
    defaultMessage: 'Current',
  },
  delete: {
    id: 'projects.messages.delete',
    defaultMessage: 'Delete',
  },
  deleted: {
    id: 'projects.messages.deleted',
    defaultMessage: 'Deleted',
  },
  deletedAt: {
    id: 'projects.messages.deletedAt',
    defaultMessage: 'Deleted at',
  },
  deleting: {
    id: 'projects.messages.deleting',
    defaultMessage: 'Deleting...',
  },
  edit: {
    id: 'projects.messages.edit',
    defaultMessage: 'Edit',
  },
  elasticsearch: {
    id: 'projects.messages.elasticsearch',
    defaultMessage: 'Elasticsearch',
  },
  essentials: {
    id: 'projects.messages.essentials',
    defaultMessage: 'Essentials',
  },
  fleet: {
    id: 'projects.messages.fleet',
    defaultMessage: 'Fleet',
  },
  kibana: {
    id: 'projects.messages.kibana',
    defaultMessage: 'Kibana',
  },
  initializing: {
    id: 'projects.messages.initializing',
    defaultMessage: 'Initializing',
  },
  manage: {
    id: 'projects.messages.manage',
    defaultMessage: 'Manage',
  },
  na: {
    id: 'projects.messages.na',
    defaultMessage: 'n/a',
  },
  nDays: {
    id: 'projects.messages.nDays',
    defaultMessage: '{n} days',
  },
  none: {
    id: 'projects.messages.none',
    defaultMessage: 'None',
  },
  open: {
    id: 'projects.messages.open',
    defaultMessage: 'Open',
  },
  orgId: {
    id: 'projects.messages.orgId',
    defaultMessage: 'Organization ID',
  },
  project: {
    id: 'projects.messages.project',
    defaultMessage: 'Project',
  },
  projectId: {
    id: 'projects.messages.projectId',
    defaultMessage: 'Project ID',
  },
  projectType: {
    id: 'projects.messages.projectType',
    defaultMessage: 'Project type',
  },
  projectName: {
    id: 'projects.messages.projectName',
    defaultMessage: 'Project name',
  },
  connectionDetails: {
    id: 'project.messages.connectionDetails',
    defaultMessage: 'Connection details',
  },
  region: {
    id: 'projects.messages.region',
    defaultMessage: 'Region',
  },
  regions: {
    id: 'projects.messages.regions',
    defaultMessage: 'Regions',
  },
  learnMore: {
    id: 'projects.messages.learnMore',
    defaultMessage: 'Learn more',
  },
  save: {
    id: 'projects.messages.save',
    defaultMessage: 'Save',
  },
  saving: {
    id: 'projects.messages.saving',
    defaultMessage: 'Saving...',
  },
  searchBoost: {
    id: 'projects.messages.searchBoost',
    defaultMessage: 'Search Boost',
  },
  searchBoostWindow: {
    id: 'projects.messages.searchBoostWindow',
    defaultMessage: 'Search Boost Window',
  },
  dataRetention: {
    id: 'projects.messages.dataRetention',
    defaultMessage: 'Data Retention',
  },
  searchPower: {
    id: 'projects.messages.searchPower',
    defaultMessage: 'Search Power',
  },
  sorryErrorOccurred: {
    id: 'projects.messages.sorryErrorOccurred',
    defaultMessage: 'Sorry, looks like an error occurred',
  },
  solution: {
    id: 'projects.messages.solution',
    defaultMessage: 'Solution',
  },
  solutions: {
    id: 'projects.messages.solutions',
    defaultMessage: 'Solutions',
  },
  suspend: {
    id: 'projects.messages.suspend',
    defaultMessage: 'Suspend',
  },
  suspended: {
    id: 'projects.messages.suspended',
    defaultMessage: 'Suspended',
  },
  suspending: {
    id: 'projects.messages.suspending',
    defaultMessage: 'Suspending',
  },
  type: {
    id: 'projects.messages.type',
    defaultMessage: 'Type',
  },
  unknown: {
    id: 'projects.messages.unknown',
    defaultMessage: 'Unknown',
  },
  view: {
    id: 'projects.messages.view',
    defaultMessage: 'View',
  },
  linkActionAriaLabel: {
    id: 'projects.messages.link.ariaLabel',
    defaultMessage: '{action} {name}',
  },
  onDemandPreset: {
    id: 'searchPower.elasticsearch.preset_onDemand',
    defaultMessage: 'On-demand',
  },
  performantPreset: {
    id: 'searchPower.elasticsearch.preset_performant',
    defaultMessage: 'Performant',
  },
  highThroughputPreset: {
    id: 'searchPower.elasticsearch.preset_highThroughput',
    defaultMessage: 'High-throughput',
  },
  custom: {
    id: 'project.searchPower.custom',
    defaultMessage: 'Custom',
  },
  defaultProjectName: {
    id: 'project.messages.defaultProjectName',
    defaultMessage: `My {label} project`,
  },
})
