/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import { noop } from 'lodash'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiLoadingSpinner } from '@elastic/eui'

import VideoPlayer from '@modules/VideoPlayer'
import TrialFlowContainer from '@modules/discovery-questions-pages/TrialFlowContainer'
import { useGetDeploymentQuery } from '@modules/deployment-creation-api/hooks'
import { getKibanaDeploymentDeepLink } from '@modules/discovery-questions-lib/steps'
import {
  useOnboardingMetadataFromLocalStorage,
  useOnboardingToken,
} from '@modules/discovery-questions-lib/hooks'
import { useProfile } from '@modules/profile-lib/hooks'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { getUserUsecase } from '@modules/profile-lib'
import { StepLayout } from '@modules/cui/Step/StepLayout'
import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import { getSolutionView } from '@modules/deployment-creation-wizard/lib'

// eslint-disable-next-line import/no-restricted-paths
import { getGettingStartedType } from '@/lib/stackDeployments/selectors/stackDeployment'
// eslint-disable-next-line import/no-restricted-paths
import { getLinks } from '@/lib/deployments/links'
// eslint-disable-next-line import/no-restricted-paths
import { isAnyResourceChanging } from '@/lib/stackDeployments/selectors/configurationChanges'
// eslint-disable-next-line import/no-restricted-paths
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors/endpoints'
// eslint-disable-next-line import/no-restricted-paths
import { kibanaGettingStartedUrl } from '@/lib/serviceProviderDeepLinks'
// eslint-disable-next-line import/no-restricted-paths
import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'

import type { FC, FunctionComponent } from 'react'

type UrlParams = {
  id: string
}

const REDIRECT_TIMEOUT_MILIS = 5000

const DeploymentInitializationScreen: FunctionComponent = () => {
  const { id } = useParams<UrlParams>()

  const [_, setOnboardingMetadata] = useOnboardingMetadataFromLocalStorage()

  const { data: deployment } = useGetDeploymentQuery(id)

  const redirectUrl = useKibanaRedirectURLWhenReady(id)

  const [shouldShowSsoUrlCallout, setShouldShowSsoUrlCallout] = useState(false)

  useEffect(() => {
    if (redirectUrl) {
      setOnboardingMetadata({})

      window.location.href = redirectUrl

      const redirectTimeout = setTimeout(() => {
        setShouldShowSsoUrlCallout(true)
      }, REDIRECT_TIMEOUT_MILIS)

      return () => clearTimeout(redirectTimeout)
    }

    return noop
  }, [redirectUrl, setOnboardingMetadata])

  if (redirectUrl === null) {
    // Give faster feedback to the user, even if the deployment and flags data is not loaded yet
    return (
      <TrialFlowContainer>
        <StepLayout
          title={
            <EuiFlexGroup>
              <EuiLoadingSpinner size='xl' />
              <FormattedMessage
                id='discovery-questions.deployment-initialization.title'
                defaultMessage='Hang tight! We are setting up your environment.'
              />
            </EuiFlexGroup>
          }
          description={
            <FormattedMessage
              id='discovery-questions.deployment-initialization.description'
              defaultMessage='This process usually takes 3-5 minutes.'
            />
          }
        >
          <VideoPlayer uuid={'fPzN7ZgKG1NkwzxjfQDWYr'} />
        </StepLayout>
      </TrialFlowContainer>
    )
  }

  if (shouldShowSsoUrlCallout && deployment) {
    return <SsoUrlCallout deployment={deployment} />
  }

  return null
}

type SsoUrlCalloutProps = {
  deployment: DeploymentGetResponse
}

const SsoUrlCallout: FC<SsoUrlCalloutProps> = ({ deployment }) => (
  <EuiCallOut title='This was unexpected' color='warning' iconType='warning'>
    <p>
      <FormattedMessage
        id='discovery-questions.deployment-initialization.error.redirect'
        defaultMessage='We were unable to redirect you automatically. Click the button below to open your deployment'
      />
    </p>
    <EuiButton href={deployment.resources.kibana[0]?.info.metadata.sso_url} fill={true}>
      <FormattedMessage
        id='discovery-questions.deployment-initialization.open-deployment'
        defaultMessage='Open deployment'
      />
    </EuiButton>
  </EuiCallOut>
)

function useKibanaRedirectURLWhenReady(id: string) {
  const { data: deployment } = useGetDeploymentQuery(id)

  const onboardingToken = useOnboardingToken()

  const kibanaDeepLink = getKibanaDeploymentDeepLink(onboardingToken)

  const [
    isFlagUsable,
    { guidedOnboarding: showGuidedOnboardingPage, deeplinkToSearch, deeplinkToSecurity },
  ] = useFlagsWhenLoaded()

  const profile = useProfile()

  if (deployment === undefined || !isFlagUsable || isDeploymentChangingPlan(deployment)) {
    return null
  }

  if (kibanaDeepLink) {
    const deepLink = getDeploymentResourceEndpoint({
      deployment,
      sliderInstanceType: 'kibana',
      getDeepLink: () => kibanaDeepLink,
    })

    if (deepLink) {
      return deepLink
    }
  } else {
    const kibanaResource = getFirstSliderClusterFromGet({
      deployment,
      sliderInstanceType: `kibana`,
    })

    const discoveryUseCase = profile ? getUserUsecase(profile) : undefined

    const ssoURL = kibanaGettingStartedUrl({
      resource: kibanaResource,
      showGuidedOnboardingPage,
      discoveryUseCase,
      deeplinkToSecurity,
      deeplinkToSearch,
      solutionType: getSolutionView(deployment),
    })

    return ssoURL
  }

  return null
}

function isDeploymentChangingPlan(deployment: DeploymentGetResponse | null) {
  if (deployment == null) {
    return false
  }

  const instanceType = getGettingStartedType({ deployment })

  const linkInfo =
    instanceType === `elasticsearch` ? [] : getLinks({ deployment, show: instanceType })

  const changingPlan = isAnyResourceChanging({ deployment })

  return (linkInfo.length > 0 && !linkInfo[0]?.available && changingPlan) || changingPlan
}

export default DeploymentInitializationScreen
